<template>
  <div v-show="!showModal">
    <a-spin :spinning="loading">
      <a-form ref="form" name="form" :model="formState" @finish="onSearch">
        <a-row>
          <a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
            <a-select placeholder="请选择所属影院" v-model:value="formState.cinemaId" allow-clear style="width: 180px;">
              <a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="卖品名称" name="goodsStockTitle" class="ui-form__item">
            <a-input v-model:value="formState.goodsStockTitle" placeholder="请输入卖品名称"/>
          </a-form-item>

          <a-form-item label="操作人" name="logUserName" class="ui-form__item">
            <a-input v-model:value="formState.logUserName" placeholder="请输入操作人"/>
          </a-form-item>

          <a-form-item class="ui-form__item" label="变动类型" name="logType">
            <a-select v-model:value="formState.logType" placeholder="请选择变动类型" allow-clear style="width: 190px;">
              <a-select-option :value="1">上架影院</a-select-option>
              <a-select-option :value="2">编辑库存</a-select-option>
              <a-select-option :value="3">用户下单</a-select-option>
              <a-select-option :value="4">取消支付</a-select-option>
              <a-select-option :value="5">卖品核销</a-select-option>
              <a-select-option :value="6">用户退款</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="变动时间"  name="createTime" class="ui-form__item">
            <a-range-picker v-model:value="formState.createTime"></a-range-picker>
          </a-form-item>
        </a-row>

        <a-row>
          <a-col :span="18">
           <exportReport type="storeGoodsStockLogExportStrategy" :searchData="searchData"></exportReport>
          </a-col>
          <a-col :span="6" style="text-align: right;">
            <a-button type="primary" html-type="submit">搜索</a-button>
            <a-button style="margin-left: 20px;" @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>

      <a-table style="margin-top: 10px;" :columns="columns" :dataSource="list" rowKey="id" :pagination="pagination" :scroll="{ x: 1800 }">
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'type'">
            <a-tag color="blue">单品</a-tag>
          </template>
          <template v-if="column.key === 'createTime'">
            {{ transDateTime(record.createTime) }}
          </template>
          <template v-if="column.key === 'logType'">
            <div v-if="record.logType === 1">上架影院</div>
            <div v-if="record.logType === 2">编辑库存</div>
            <div v-if="record.logType === 3">用户下单</div>
            <div v-if="record.logType === 4">取消支付</div>
            <div v-if="record.logType === 5">卖品核销</div>
            <div v-if="record.logType === 6">用户退款</div>
          </template>
          <template v-if="column.key === 'logUserName'">
            <div v-if="record.logType !== 3 && record.logType !== 4 && record.logType !== 6">
              {{ record.logUserName || '-' }}
            </div>
            <div v-else>
              -
            </div>
          </template>
          <template v-if="column.key === 'change'">
            {{ record.logAfterNum - record.logBeforeNum }}
          </template>
        </template>
      </a-table>
    </a-spin>
  </div>
  <publish v-if="showModal" :inventory="inventory" :isEdit="isEdit" :isSee="isSee" :id="id" :type="type" form="store" @back="onBack"></publish>
</template>

<script>
import publish from '@/views/snackManage/goods/publish/publish.vue';
import exportReport from '@/components/exportReport/exportReport.vue';
import { getCinemaList } from '@/service/modules/cinema.js';
import {
  getStoreGoodsStockLogList
} from "@/service/modules/goods";
export default {
  components: { exportReport, publish },
  data() {
    return {
      imageUrl: '',
      inventory: false,
      loading: false,
      isShowEdit: false,
      cinemaAllList: [],
      numModelRef: {},
      formState: {},
      // status: 0,
      searchData: {},
      selectedRowKeys: [],
      selectedListData: [],
      list: [],
      columns: [{
        title: '所属影院',
        dataIndex: 'cinemaName'
      }, {
        title: '卖品名称',
        dataIndex: 'goodsStockTitle'
      }, {
        title: '卖品类型',
        key: 'type'
      }, {
        title: '变动时间',
        key: 'createTime'
      }, {
        title: '变动类型',
        key: 'logType'
      }, {
        title: '变动前库存',
        dataIndex: 'logBeforeNum',
      }, {
        title: '变动后库存',
        dataIndex: 'logAfterNum',
      }, {
        title: '库存变化',
        key: 'change',
      }, {
        title: '操作人',
        key: 'logUserName',
      }],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        }
      },
      showModal: false,
      isEdit: true,
      isSee: false,
      id: 0,
      type: 2,
      showShare: false,
      isShowEditPrice: false,
      priceModelRef: {},
    }
  },
  created() {
    this.getData();
    this.getAllCinemaList();
  },
  methods: {
    onEdit(item) {
      this.id = item.id;
      this.isShowEdit = true;
      this.numModelRef = {};
      this.numModelRef.nowStock = item.stock;
    },
    onBack(isRef) {
      this.showModal = false;
      if (isRef) {
        this.$nextTick(() => {
          this.getData();
        })
      }
    },
    onSearch() {
      this.pagination.current = 1;
      this.searchData = JSON.parse(JSON.stringify(this.formState));
      this.getData();
    },
    reset() {
      this.$refs.form.resetFields();
      this.onSearch();
    },
    async getData() {
      this.loading = true;
      if(this.searchData.createTime && this.searchData.createTime.length === 2) {
        this.searchData.createStartTime = this.moment(this.formState.createTime[0].startOf('day')).unix();
        this.searchData.createEndTime = this.moment(this.formState.createTime[1].endOf('day')).unix();
      }
      try {
        let ret = await getStoreGoodsStockLogList({
          page: this.pagination.current,
          pageSize: this.pagination.pageSize,
          ...this.searchData
        })
        this.loading = false;
        if (ret.code === 200) {
          this.pagination.total = ret.data.totalCount;
          this.list = ret.data.list;
        }
      } catch(e) {
        this.loading = false;
      }
    },
    async getAllCinemaList(organizationId) {
      this.cinemaAllList = [];
      // this.formState.cinemaId = 0;
      let ret = await getCinemaList({
        page: 1,
        pageSize: 999999,
        organizationId: organizationId ? organizationId : undefined
      })
      if (ret.code === 200) {
        this.cinemaAllList = ret.data.list;
      }
    },
  }
}
</script>

<style scoped>
.ui-form__item {
  margin-right: 30px;
}
</style>
